import * as React from "react";
import { Link, graphql } from "gatsby";
import { kebabCase } from "lodash";

import Layout from "../components/layout";
import Seo from "../components/seo";
import BlurbMenu from "../components/blurb-menu";
import Subscribe from "../components/subscribe";

const StaffTemplate = ({ data, location }) => {
  const post = data.nodeStaff;
  const siteTitle = data.site.siteMetadata?.title || ``;
  const { previous, next } = data;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.title}
        description={post.body?.summary || null}
        location={location}
      />
      <article className="staff" itemScope itemType="http://schema.org/Article">
        <BlurbMenu props={post} menuProp="about" />
      </article>
      <nav className="bg-gray-50">
        <ul className="flex flex-wrap justify-between list-none container pb-12">
          <li>
            {previous && (
              <Link
                to={
                  previous.path?.alias ||
                  "/our-staff/" + kebabCase(previous.title)
                }
                rel="prev"
              >
                ← {previous.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link
                to={next.path?.alias || "/our-staff/" + kebabCase(next.title)}
                rel="next"
              >
                {next.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      <Subscribe hasTestimonial={false} />
    </Layout>
  );
};

export default StaffTemplate;

export const pageQuery = graphql`
  query StaffBySlug(
    $id: String!
    $previousStaffId: String
    $nextStaffId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    nodeStaff(id: { eq: $id }) {
      id
      title
      body {
        processed
        summary
      }
      field_staff_title
      field_staff_phone_number
      field_staff_image_focus
      field_staff_email
      relationships {
        field_department {
          name
        }
        field_staff_image {
          ... on media__image {
            field_media_image {
              alt
            }
            relationships {
              field_media_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1024)
                  }
                  publicURL
                }
              }
            }
          }
        }
      }
    }
    previous: nodeStaff(id: { eq: $previousStaffId }) {
      title
      path {
        alias
      }
    }
    next: nodeStaff(id: { eq: $nextStaffId }) {
      title
      path {
        alias
      }
    }
  }
`;
// date(formatString: "MMMM DD, YYYY")
// description
// excerpt(pruneLength: 160)
